module.exports = {
  messages: {
    CN: {
      SelectSubscriptionPlan: "选择订阅版本",
      BillingInformation: "订单详情",
      Payment: "支付",
      lastName: "姓",
      firstName: "名",
      SaveChanges: "保存更改",
      billInfo: "付费详情",
      TotalCost: "总费用",
      CompanyName: "公司",
      CompanySize: "规模",
      Country: "国家",
      City: "城市",
      ZIPCode: "邮政编码",
      Address: "地址",
      Back: "返回",
      NextStep: "下一步",
      OrderSummary: "订单",
      OriginalPrice: "原价",
      Discount: "优惠",
      Total: "总计",

      EnterPaymentDetails: "信用卡详情",
      CardNumber: "卡号",
      ExpirationDate: "过期时间",
      SecurityCode: "安全码",
      Subscribe: "订阅",

      SubscriptionPlan: "订阅版本",
      lite: "精简版",
      pro: "专业版",
      prime: "至尊版",
      BillingCycle: "支付周期",
      MonthlyPayment: "按月支付",
      AnnualPayment: "按年支付",
      Botselection: "机器人选择",
      AISalesRep: "AI销售代表",
      AIRecruiter: "AI招聘专员",
      SetasDefault: "设为默认",
      TotalPrice: "总价",
      BillingInformations: "计费信息",
      AddBillingInformation: "新增计费信息",
      EditBillingInformation: "修改计费信息",
      EnterCompanyName: "请输入公司的名称",
      SelectCountry: "请选择国家",
      EnterCityName: "请填写城市",
      EnterZipCode: "请正确输入邮政编码",
      EnterAddress: "请填写地址",
      Save: "保存",
      Cancel: "取消",
      SelectCardNumber: "请选择一个卡号",
      DoyoudiscountCode: "是否有折扣码",
      discountCodeOnce: "每个折扣码只可以使用一次",
      codeExpired: "折扣码已过期",
      AddaNew: "添加新卡",
      Enter: "请输入",
      ExpirationDateFormat: "有效期请输入MM/YY格式",
      InvalidCardExpirationDate: "卡有效期不正确",
      CardExpirationDate: "卡有效期已过",
      EnterValidCVCCode: "请正确输入CVC码",
      Order: "订单",
      Purchase: "订阅",
      OrderHistory: "订单历史",
      OrderDate: "订单日期",
      SubscriptionType: "订阅方式",
      Price: "价格",
      LinkAccount: "绑定账号",
      GotoLinkAccount: "去绑定",
      ExclusiveDiscountCode: "专属折扣码",
      ValidityPeriod: "有效期",
      Discount2: "折扣",
      TotalUses: "总次数",
      RemainingUses: "剩余次数",
      CommissionManagement: "返佣管理",
      ID: "ID",
      SubscriptionDate: "订阅日期",
      CommissionDate: "返佣日期",
      FullyRefunded: "暂停返佣，已返还全部",
      UserRefunded: "暂停返佣，用户已退款",
      DiscountedPrice: "优惠价格",
      FinalPaidAmount: "实付价格",
      CommissionEligible: "是否返佣",
      Yes: "是",
      No: "无",
      ExpectedCommission: "应返佣金",
      PaidCommission: "已返佣金",
      PendingCommission: "待返佣金",
      LastName: "姓",
      FirstName: "名",
      pleaseEnterLastName: "请输入姓",
      enterFirstName: "请输入名",
      enterEmail: "请输入邮箱",
      validEmail: "请填写正确的邮箱",
      validPhone: "电话号码请输入数字",
      phoneNumber: "电话",
      name: "姓名",
      Email: "邮箱",
      EnterDiscountCode: "请输入折扣码",
      selectBillingInformation: "请选择计费信息",
      saveInfo: "请完善保存计费信息后再继续下一步",
      orderInfo: "订单信息",
      freeTrialTips:
        "账号已过试用期，所有的任务都已暂停，想要继续使用strategyBrain,请订阅",

      // plan
      Monthly: "月付",
      Annually: "年付(83折)",
      Allfeatures: "AI销售代表和AI招聘官的全部功能",
      AllcoreAIRecruiter: "AI招聘官的核心功能",
      ReducecostsandImprove: "AI助力您大幅提高效率并降低成本",
      ExploreourPlatform: "7天免费试用全部功能",
      ChooseEitherAISale100: `<span style='color:#6caba3'>选择AI销售代表或AI招聘官</span>，套餐包含添加40个好友和100次对话`,
      ChooseEitherAISale500: `<span style='color:#6caba3'>选择AI销售代表或AI招聘官</span>，套餐包含添加120个好友和500次对话`,
      GainunlimitedAccess:
        "无限使用所有<span style='color:#6caba3'>AI销售代表和AI招聘官的功能</span>，包括无限添加好友",
      FirstWeek: "首周",
      day: "天",
      paidMonthly: "按天计费，按月付费",
      paidAnnually: "按天计费，按年付费",
      BestSeller: "热销",
      Purchase1: "支付",
      Sales: "销售代表",
      Recruiter: "招聘官",
      AIselection: "AI功能选择",
      CoreFeature: "核心功能",
      Campaign: "任务",
      SearchResults: "搜索列表",
      ConnectionRequest: "好友请求",
      Connection: "好友",
      Conversation: "对话",
      DashBoard: "看板",
      HRExpert: "AI人力专家",
      AllcorefeaturesofAISalesRep: "AI销售代表的所有核心功能",
      Partiallysupported: "部分支持",
      Notsupported: "不支持",
      Fullysupported: "全部支持",
      Unlimited: "无限",
      "40hoursofworkperweek": "每周工作40小时：",
      ClientsAndCandidatesNeed: "人工搜索客户和候选人并添加好友",
      EachClientAndCandidate: "每位客户和候选人都需要人工进行个性化沟通",
      DelayedresponsesMakeTimely: "回复延迟使得及时跟进客户变得困难",
      litePlanDes: `您可以选择 <span style='color:#6caba3'>AI销售代表 或 AI招聘官</span>，套餐包含添加40个好友额度，年付仅需 99美元。`,
      proPlanDes: `您可以选择 <span style='color:#6caba3'>AI销售代表 或 AI招聘官</span>，套餐包含添加120个好友额度，年付仅需 399美元。`,
      primePlanDes: `您可以使用 <span style='color:#6caba3'>AI销售代表 和 AI招聘官 的全部功能</span>，并享受无限添加好友额度，年付仅799美元。`,
      FreeTrial: "免费版",
      Manual: "人工",
      LitePlan: "精简版",
      ProPlan: "专业版",
      PrimePlan: "至尊版",
      year: "年",
      month: "月",
      NoStrategyBrain: "无StrategyBrain",
    },

    US: {
      SelectSubscriptionPlan: "Select Subscription Plan",
      BillingInformation: "Billing Information",
      Payment: "Payment",
      lastName: "Last Name",
      firstName: "First Name",
      SaveChanges: "Save Changes",
      billInfo: "Billing Information and Payment Details",
      TotalCost: "Total Cost",
      CompanyName: "Company Name",
      CompanySize: "Company Size",
      Country: "Country",
      City: "City",
      ZIPCode: "ZIP Code",
      Address: "Address",
      Back: "Back",
      NextStep: "Next Step",
      OrderSummary: "Order Summary",
      OriginalPrice: "Original Price",
      Discount: "Discount",
      Total: "Total",

      EnterPaymentDetails: "Enter Payment Details",
      CardNumber: "Card Number",
      ExpirationDate: "Expiration Date",
      SecurityCode: "Security Code",
      Subscribe: "Subscribe",

      SubscriptionPlan: "Subscription Plan",
      lite: "lite",
      pro: "pro",
      prime: "prime",
      BillingCycle: "Billing Cycle",
      MonthlyPayment: "Monthly Payment",
      AnnualPayment: "Annual Payment",
      Botselection: "Bot selection",
      AISalesRep: "AI Sales Rep",
      AIRecruiter: "AI Recruiter",
      SetasDefault: "Set as Default",
      TotalPrice: "Total Price",
      BillingInformations: "Billing Information",
      AddBillingInformation: "Add Billing Information",
      EditBillingInformation: "Edit Billing Information",
      EnterCompanyName: "Enter Company Name",
      SelectCountry: "Select Country",
      EnterCityName: "Enter City Name",
      EnterZipCode: "Enter a Valid Zip Code",
      EnterAddress: "Enter Address",
      Save: "Save",
      Cancel: "Cancel",
      SelectCardNumber: "Select a Card Number",
      DoyoudiscountCode: "Do you have a discount code",
      discountCodeOnce: "Each account can only use a discount code once",
      codeExpired: "Discount code has expired",
      AddaNew: "Add a new",
      Enter: "Enter",
      ExpirationDateFormat: "Expiration Date (MM/YY)",
      InvalidCardExpirationDate: "Invalid Card Expiration Date",
      CardExpirationDate: "Card Expiration Date Has Passed",
      EnterValidCVCCode: "Enter a Valid CVC Code",
      Order: "Order",
      Purchase: "Purchase",
      OrderHistory: "Order History",
      OrderDate: "Order Date",
      SubscriptionType: "Subscription Type",
      Price: "Price",
      LinkAccount: "Link Account",
      GotoLinkAccount: "Go to Link Account",
      ExclusiveDiscountCode: "Exclusive Discount Code",
      ValidityPeriod: "Validity Period",
      Discount2: "Discount",
      TotalUses: "Total Uses",
      RemainingUses: "Remaining Uses",
      CommissionManagement: "Commission Management",
      ID: "ID",
      SubscriptionDate: "Subscription Date",
      CommissionDate: "Commission Date",
      FullyRefunded: "Commission Paused, Fully Refunded",
      UserRefunded: "Commission Paused, User Refunded",
      DiscountedPrice: "Discounted Price",
      FinalPaidAmount: "Final Paid Amount",
      CommissionEligible: "Commission Eligible",
      Yes: "Yes",
      No: "No",
      ExpectedCommission: "Expected Commission",
      PaidCommission: "Paid Commission",
      PendingCommission: "Pending Commission",
      LastName: "Last Name",
      FirstName: "First Name",
      pleaseEnterLastName: "please enter Last Name",
      enterFirstName: "Please enter First Name",
      enterEmail: "Please enter an Email",
      validEmail: "Please enter a valid email ",
      validPhone: "Phone number must contain only digits",
      phoneNumber: "Phone Number",
      name: "Name",
      Email: "Email",
      EnterDiscountCode: "Enter Discount Code",
      selectBillingInformation: "Please select billing information",
      saveInfo:
        "Please complete and save billing information before proceeding to the next step",
      orderInfo: "Order Details",
      freeTrialTips:
        "Your account's trial period has expired, all tasks have been paused. To continue using StrategyBrain, please subscribe",

      // plan
      Monthly: "Monthly",
      Annually: "Annually(17% OFF)",
      Allfeatures: "All features of AI Sales Rep and AI Recruiter",
      AllcoreAIRecruiter: "All core features of AI Recruiter",
      ReducecostsandImprove:
        "Reduce costs and improve efficiency significantly with AI assistance.",
      ExploreourPlatform: "Explore our platform with a free one-week trial.",
      ChooseEitherAISale100: `Choose either <span style="color:#6caba3">AI Sales Rep or AI Recruiter</span>, which includes 40 connections and 100 conversations.`,
      ChooseEitherAISale500: `Choose either <span style="color:#6caba3">AI Sales Rep or AI Recruiter</span>, which includes 120 connections and 500 conversations.`,
      GainunlimitedAccess: `Gain unlimited access to all <span style="color:#6caba3">AI Sales Rep and AI Recruiter features</span>, including unlimited connections.`,
      FirstWeek: "First week",
      day: "day",
      paidMonthly: "Daily prices, paid monthly",
      paidAnnually: "Dally prices, paid annually",
      BestSeller: "Best Seller",
      Purchase1: "Purchase",
      Sales: "Sales",
      Recruiter: "Recruiter",
      AIselection: "AI selection",
      CoreFeature: "Core Feature",
      Campaign: "Campaign",
      SearchResults: "Search Results",
      ConnectionRequest: "Connection request",
      Connection: "Connection",
      Conversation: "Conversation",
      DashBoard: "DashBoard",
      HRExpert: "HR Expert",
      AllcorefeaturesofAISalesRep: "All core features of AI Sales Rep",
      Partiallysupported: "Partially supported",
      Notsupported: "Not supported",
      Fullysupported: "Fully supported",
      Unlimited: "Unlimited",
      "40hoursofworkperweek": "40 hours of work per week.",
      ClientsAndCandidatesNeed:
        "Clients and candidates need to be manually sourced and added.",
      EachClientAndCandidate:
        "Each client and candidate requires personalized, one-on-one manual communication.",
      DelayedresponsesMakeTimely:
        "Delayed responses make timely follow-up challenging.",
      litePlanDes: `You can choose either <span style="color:#6caba3">AI Sales Rep or AI Recruiter</span>, which includes 40 connections, for an annually fee of US$99.`,
      proPlanDes: `You can choose either <span style="color:#6caba3">AI Sales Rep or AI Recruiter</span>, which includes 120 connections, for an annually fee of US$399.`,
      primePlanDes: `You can use all features of both <span style="color:#6caba3">AI Sales Rep and AI Recruiter</span> with unlimited connections for US$799 per year.`,
      FreeTrial: "Free Trial",
      Manual: "Manual",
      LitePlan: "Lite Plan",
      ProPlan: "Pro Plan",
      PrimePlan: "Prime Plan",
      year: "year",
      month: "month",
      NoStrategyBrain: "No StrategyBrain",
    },
    AR: {
      SelectSubscriptionPlan: "اختر خطة الاشتراك",
      BillingInformation: "معلومات الفوترة",
      Payment: "الدفع",
      lastName: "اللقب",
      firstName: "الاسم الأول",
      SaveChanges: "حفظ التغييرات",
      billInfo: "معلومات الفوترة وتفاصيل الدفع",
      TotalCost: "التكلفة الإجمالية",
      CompanyName: "اسم الشركة",
      CompanySize: "حجم الشركة",
      Country: "البلد",
      City: "المدينة",
      ZIPCode: "الرمز البريدي",
      Address: "العنوان",
      Back: "العودة",
      NextStep: "التالي",
      OrderSummary: "ملخص الطلب",
      OriginalPrice: "السعر الأصلي",
      Discount: "الخصم",
      Total: "الإجمالي",

      EnterPaymentDetails: "أدخل تفاصيل الدفع",
      CardNumber: "رقم البطاقة",
      ExpirationDate: "تاريخ الانتهاء",
      SecurityCode: "الرمز الأمني",
      Subscribe: "اشترك",

      SubscriptionPlan: "خطة الاشتراك",
      lite: "الخطة الأساسية",
      pro: "الخطة الاحترافية",
      prime: "الخطة الأساسية الممتازة",
      BillingCycle: "دورة الفوترة",
      MonthlyPayment: "الدفع الشهري",
      AnnualPayment: "الدفع السنوي",
      Botselection: "اختيار الروبوت:",
      AISalesRep: "ممثل المبيعات بالذكاء الاصطناعي",
      AIRecruiter: "موظف التوظيف بالذكاء الاصطناعي",
      SetasDefault: "تعيين كافتراضي",
      TotalPrice: "السعر الإجمالي",
      BillingInformations: "معلومات الفوترة",
      AddBillingInformation: "إضافة معلومات الفوترة",
      EditBillingInformation: "تعديل معلومات الفوترة",
      EnterCompanyName: "أدخل اسم الشركة",
      SelectCountry: "اختر الدولة",
      EnterCityName: "أدخل اسم المدينة",
      EnterZipCode: "يرجى إدخال رمز بريدي صالح",
      EnterAddress: "أدخل العنوان",
      Save: "حفظ",
      Cancel: "إلغاء",
      SelectCardNumber: "اختر رقم البطاقة",
      DoyoudiscountCode: "هل لديك رمز خصم",
      discountCodeOnce: "يمكن لكل حساب استخدام رمز خصم مرة واحدة فقط",
      codeExpired: "انتهت صلاحية رمز الخصم",
      AddaNew: "أضف جديدًا",
      Enter: "أدخل",
      ExpirationDateFormat: "تاريخ انتهاء الصلاحية (MM/YY)",
      InvalidCardExpirationDate: "تاريخ انتهاء صلاحية البطاقة غير صالح",
      CardExpirationDate: "انتهت صلاحية البطاقة",
      EnterValidCVCCode: "يرجى إدخال رمز CVC صالح",
      Order: "الطلب",
      Purchase: "شراء",
      OrderHistory: "سجل الطلبات",
      OrderDate: "تاريخ الطلب",
      SubscriptionType: "نوع الاشتراك",
      Price: "السعر",
      LinkAccount: "ربط الحساب",
      GotoLinkAccount: "اذهب إلى ربط الحساب",
      ExclusiveDiscountCode: "رمز خصم حصري",
      ValidityPeriod: "فترة الصلاحية",
      Discount2: "الخصم",
      TotalUses: "إجمالي عدد مرات الاستخدام",
      RemainingUses: "عدد مرات الاستخدام المتبقية",
      CommissionManagement: "إدارة العمولات",
      ID: "ID",
      SubscriptionDate: "تاريخ الاشتراك",
      CommissionDate: "تاريخ العمولة",
      FullyRefunded: "تم إيقاف العمولة، وتم استرداد المبلغ بالكامل",
      UserRefunded: "تم إيقاف العمولة، وتم استرداد المبلغ للمستخدم",
      DiscountedPrice: "السعر المخفض",
      FinalPaidAmount: "المبلغ المدفوع النهائي",
      CommissionEligible: "مؤهل للحصول على العمولة",
      Yes: "نعم",
      No: "لا",
      ExpectedCommission: "العمولة المتوقعة",
      PaidCommission: "العمولة المدفوعة",
      PendingCommission: "العمولة المعلقة",
      LastName: "اللقب",
      FirstName: "الاسم الأول",
      pleaseEnterLastName: "أدخل اسم العائلة",
      enterFirstName: "أدخل الاسم الأول",
      enterEmail: "أدخل البريد الإلكتروني",
      validEmail: "يُرجى إدخال البريد الإلكتروني الصحيح",
      validPhone: "يُرجى إدخال أرقام فقط في حقل رقم الهاتف",
      phoneNumber: "الهاتف",
      name: "الاسم",
      Email: "البريد الإلكتروني",
      EnterDiscountCode: "يُرجى إدخال رمز الخصم",
      selectBillingInformation: "يرجى اختيار معلومات الفوترة",
      saveInfo:
        "يُرجى إكمال وحفظ معلومات الفوترة قبل المتابعة إلى الخطوة التالية",
      orderInfo: "معلومات الطلب",
      freeTrialTips:
        "انتهت فترة تجربة الحساب، وتم إيقاف جميع المهام. إذا كنت ترغب في الاستمرار في استخدام StrategyBrain، يرجى الاشتراك",
      // plan
      Monthly: "شهريًا",
      Annually: "سنويًا (خصم 17%)",
      Allfeatures: "جميع ميزات المبيعات والتوظيف",
      AllcoreAIRecruiter: " جميع الميزات الأساسية لأداة التوظيف",
      ReducecostsandImprove:
        "قلل التكاليف وحسّن الكفاءة بشكل كبير بمساعدة الذكاء الاصطناعي.",
      ExploreourPlatform:
        "استكشف منصتنا من خلال نسخة تجريبية مجانية لمدة أسبوع.",
      ChooseEitherAISale100: `اختر <span style='color:#6caba3'>إما روبوت المبيعات أو روبوت التوظيف</span>، والذي يشمل 40 اتصالًا و100 محادثة.`,
      ChooseEitherAISale500: `اختر <span style='color:#6caba3'>إما روبوت المبيعات أو روبوت التوظيف</span>، والذي يشمل 120 اتصالًا و500 محادثة.`,
      GainunlimitedAccess: `احصل على وصول غير محدود إلى جميع ميزات <span style="color:#6caba3">روبوت المبيعات وروبوت التوظيف</span>، بما في ذلك الاتصالات غير المحدودة.`,
      FirstWeek: "الأسبوع الأول",
      day: "يوم",
      paidMonthly: "أسعار يومية، تُدفع شهريًا",
      paidAnnually: "أسعار يومية، تُدفع سنويًا",
      BestSeller: "الأكثر مبيعًا",
      Purchase1: " شراء",
      Sales: "المبيعات",
      Recruiter: "مسؤول التوظيف",
      AIselection: "اختيار الذكاء الاصطناعي",
      CoreFeature: "الميزة الأساسية",
      Campaign: "الحملة",
      SearchResults: "قائمة البحث عن الأشخاص",
      ConnectionRequest: "طلب الاتصال",
      Connection: "الاتصالات",
      Conversation: "المحادثة",
      DashBoard: "لوحة التحكم",
      HRExpert: "خبير الموارد البشرية",
      AllcorefeaturesofAISalesRep: "جميع الميزات الأساسية لممثل المبيعات",
      Partiallysupported: "مدعوم جزئيًا",
      Notsupported: "غير مدعومة",
      Fullysupported: "مدعوم بالكامل",
      Unlimited: "غير محدودة",
      "40hoursofworkperweek": "٤٠ ساعة عمل أسبوعيًا.",
      ClientsAndCandidatesNeed:
        "يحتاج العملاء والمرشحون إلى البحث اليدوي والإضافة يدويًا.",
      EachClientAndCandidate:
        "يتطلب كل عميل ومرشح تواصلًا يدويًا فرديًا وشخصيًا.",
      DelayedresponsesMakeTimely:
        "تجعل الردود المتأخرة المتابعة في الوقت المناسب أمرًا صعبًا.",
      litePlanDes: `يمكنك اختيار <span style="color:#6caba3">إما المبيعات أو التوظيف</span>، والذي يشمل 40 اتصالًا، مقابل رسوم سنوية قدرها 99 دولارًا أمريكيًا`,
      proPlanDes: `يمكنك اختيار <span style="color:#6caba3">إما المبيعات أو التوظيف</span>، والذي يشمل 120 اتصالًا، مقابل رسوم سنوية قدرها 399 دولارًا أمريكيًا.`,
      primePlanDes: `يمكنك استخدام جميع ميزات <span style="color:#6caba3">كل من المبيعات والتوظيف</span> مع اتصالات غير محدودة مقابل 799 دولارًا أمريكيًا سنويًا. `,
      FreeTrial: "نسخة تجريبية مجانية",
      Manual: "يدوي",
      LitePlan: "الخطة الأساسية",
      ProPlan: "الخطة الاحترافية",
      PrimePlan: "الخطة الأساسية الممتازة",
      year: "دولار",
      month: "شهريًا",
      NoStrategyBrain: "بدون StrategyBrain",
    },
  },
};
